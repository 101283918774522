export const links = [
    {
    title: 'CONTACT',
    links: [
      {
        label: 'Statys Connect DMCC'
      },
      {
        label: 'contact@statysconnect.com',
        href: 'mailto:contact@statysconnect.com',
      },
      {
        label: 'Jumeirah Lakes Towers, Cluster O, Reef Tower DMCC 29-02, Dubai, United Arab Emirates',
        href: '#',
      },
    ],
  },
  {
    title: 'PAGES',
    links: [
      {
        label: 'Insights',
        href: '/insights',
      },
      {
        label: 'About Us',
        href: '/about',
      },
      {
        label: 'Contact',
        href: '/contact',
      },
       {
        label: 'Privacy Policy',
        href: '/privacy-policy',
      },
    ],
  },

  // {
  //   title: 'SOCIAL',
  //   links: [
  //     {
  //       label: 'Linkedin',
  //       href: 'https://www.linkedin.com/company/mediconnectco/',
  //     },
  //     {
  //       label: 'Facebook',
  //       href: 'https://www.facebook.com/profile.php?id=100089324151962',
  //     },
  //  ,
  //   ],
  // },
  

]
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  chakra,
  useBoolean,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, navigate } from "gatsby"
import React, {useState} from "react";

import { AiOutlineMenu } from "react-icons/ai";
import {Logo} from "../../images/Logo"

export const Header = () => {
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();

  const [flag, setFlag] = useBoolean()

  return (
    <React.Fragment>
      <chakra.header bg={bg} w="full" px={{ base: 2, sm: 4 }} py={4} shadow="md" style={{position: `fixed`, zIndex: "100"}} >
        <Flex alignItems="center" justifyContent="space-between" mx="auto" maxW="1330px">
          <Flex height="40px">
            {/* <chakra.a href="/" title="Statys Connect" display="flex" alignItems="center"></chakra.a> */}
            <Link to="/" aria-label="Link to home page"><Logo fill="blue.500" height="52px"></Logo></Link>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack spacing={1} mr={1} color="brand.500" display={{ base: "none", md: "inline-flex" }}>
              {/* <Button onClick={()=>{navigate("/news/")}} variant="ghost" color="gray.700" marginInlineStart="10px !important" >News</Button> */}
              <Button aria-label="Button link to blog page" onClick={()=>{navigate("/insights/")}} variant="ghost" color="gray.700" marginInlineStart="10px !important">Insights</Button>
              {/* <Button aria-label="Button link to a contributor form page" onClick={()=>{navigate("/become-a-contributor/")}} variant="ghost" color="gray.700" marginInlineStart="10px !important">Become contributor</Button> */}
              <Button onClick={()=>{navigate("/about/")}} variant="ghost" color="gray.700" marginInlineStart="10px !important">About Us</Button>
              <Button aria-label="Button link to a contact page" onClick={()=>{navigate("/contact/")}} variant="solid" backgroundColor="#3554CA" color="#fff" marginInlineStart="10px !important">Contact Us</Button>
            
            </HStack>
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={setFlag.toggle}
                zIndex="10"
              />
                <Drawer isOpen={flag}  >
                <DrawerOverlay />
                <DrawerContent >
                  <DrawerBody position="fixed" top="80px" >
                        {/* <Button onClick={()=>{navigate("/news/")}} variant="ghost" color="gray.700" w="full">News</Button> */}
                        <Button aria-label="Button link to blog posts" onClick={()=>{navigate("/insights/")}} variant="ghost" justifyContent="flex-start" color="gray.700" w="full">Insights</Button>
                        {/* <Button aria-label="Button link to a contributor form page" onClick={()=>{navigate("/become-a-contributor/")}} variant="ghost" justifyContent="flex-start" color="gray.700" w="full">Become contributor</Button> */}
                        <Button onClick={()=>{navigate("/about/")}} variant="ghost" color="gray.700" justifyContent="flex-start" w="full">About Us</Button>
                        <Button aria-label="Button link to a contact page" onClick={()=>{navigate("/contact/")}} variant="solid" backgroundColor="#3554CA" color="#fff" w="full">Contact Us</Button>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>

              {/* <Box position="fixed" width='250px' height="100%" top="0" right="0" backgroundColor="red" display={flag ? "flex" : "none"}>
              
              </Box> */}

              {/* <VStack pos="absolute" top={0} left={0} right={0} display={mobileNav.isOpen ? "flex" : "none"} flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bg}
                spacing={3}
                rounded="sm"
                shadow="sm"
              >
              <CloseButton aria-label="Close menu" onClick={mobileNav.onClose}/>
                <Button onClick={()=>{navigate("/news/")}} variant="ghost" color="gray.700" w="full">News</Button>
                <Button onClick={()=>{navigate("/insights/")}} variant="ghost" color="gray.700" w="full">Insights</Button>
                <Button onClick={()=>{navigate("/become-a-contributor/")}} variant="ghost" color="gray.700" w="full">Become contributor</Button>
                <Button onClick={()=>{navigate("/about/")}} variant="ghost" color="gray.700" w="full">About Us</Button>
                <Button onClick={()=>{navigate("/contact/")}} variant="solid" backgroundColor="#3554CA" color="#fff" w="full">Contact Us</Button>
              </VStack> */}
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
}

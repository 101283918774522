import * as React from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

import { Logo } from '../../images/Logo'
import { links } from './_data'

const FooterNew = () => {

    return (
    <Box bg="#F1F1F4" color="on-acccent">
        <Container as="footer" role="contentinfo" maxW="120ch">
            <Stack
                justify="space-between"
                align="start"
                direction={{
                    base: 'column-reverse',
                    lg: 'row',
                }}
                py={{
                    base: '12',
                    md: '16',
                }}
                spacing="8"
            >
            <Stack
                maxW="271px"
                spacing={{
                        base: '6',
                        md: '8',
                    }}
                    align="start"
                >
                    <Logo height="100%" width="200px" fill="blue.600" />
                    <Text color="on-accent-muted" fontSize="16px" marginTop="48px !important">Bringing the best in an ecosystem of solutions to manage, upgrade and evolve your reseller business.</Text>
                    </Stack>
                <Divider orientation="vertical" display={{lg: "block", base: "none"}} borderColor="#A3A6B7" height="202px" />
                <Divider orientation="horizontal" display={{lg: "none", base: "block"}} borderColor="#A3A6B7" marginBottom="24px" />
   
                <SimpleGrid
                    columns={{
                        base: 1,
                        md: 3,
                    }}
                    gap="8"
                    width={{
                        base: 'full',
                        lg: '60%',
                    }}
                >
                    {links.map((group, idx) => (
                        <Stack
                            key={idx}
                            spacing="4"
                            minW={{
                                lg: '40',
                            }}
                        >
                            <Text fontSize="sm" fontWeight="bold" color="gray.800" fontFamily="Gotham Medium">
                                {group.title}
                            </Text>
                            <Stack spacing="5" shouldWrapChildren>
                                {group.links.map((link, idx) => (
                                    <Link key={idx} variant="link-on-accent" href={link.href} padding="0px" color="#1A202C !important" fontWeight="600" _hover={{textDecoration:"underline !important", textDecorationColor:"#3554CA !important"}}  >
                                        {link.label}
                                    </Link>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </SimpleGrid>
            </Stack>
        </Container>
        </Box>
    )
}

export default FooterNew;